@import "_app.scss";


//colors
$white: #fff;
$color_mountain_mist_approx: #979797;
$color_celeste_approx: #ccc;
$color_gallery_approx: #eee;
$alabaster_100: rgba(250,250,250,1);
$color_mercury_approx: rgba(230,230,230,1);
$color_alto_approx: #dcdcdc;
$color_delta_approx: #a4a4a4;
$color_azure_approx: #455a9e;
$color_gunsmoke_approx: #848484;
$color_desert_storm_approx: #f8f8f8;
$color_rangoon_green_approx: #1d1d1d;
$color_suva_gray_approx: #868686;
$color_fun_blue_approx: #1260a3;
$color_periwinkle_gray_approx: #c3cee1;
$mercury: #e5e5e5;
$color_tundora_approx: #444;
$color_tory_blue_approx: #0e57aa;

//fonts
$font_0: "rancho";
$font_1: "arial";
$font_2: "sans-serif";
$font_3: "myriad pro";
$font_4: "arial";
$font_5: "sans-serif";
$font_6: "rancho";
$font_7: "cursive";
$font_8: "arial";
$font_9: "sans-serif";

//urls
$url_0: url("/compiled/fonts/ranchoregular.eot");
$url_1: url("/compiled/fonts/ranchoregular.eot?#iefix");
$url_2: url("/compiled/fonts/ranchoregular.woff");
$url_3: url("/compiled/fonts/ranchoregular.ttf");
$url_4: url("/compiled/fonts/ranchoregular.svg#ranchoregular");
$url_5: image-url("logo-header.png");
$url_6: image-url("bg-loginDropdown.png");
$url_8: image-url("global/icons/icons-devices.png");
$url_9: image-url("app-signup/icons-moreInfo-li.png");
$url_10: image-url("icons/icons-48px.png");
$url_11: image-url("icons/icons-90px.png");
$url_12: image-url("icons/icons-grey.png");
$url_13: image-url("icons/icons-social-90.png");
$url_14: image-url("bg-testimonial-pointer.png");
$url_15: image-url("icons/arrow-blue-curved-up.png");

//@extend-elements
//original selectors
//.panel .devices li, #footer .devices li
%extend_1 {
  list-style: none;
  font-size: 13px;
  color: $color_gunsmoke_approx;
}

//original selectors
//.panel .devices li[class^="icon-"], #footer .devices li[class^="icon-"]
%extend_2 {
  width: 30px;
  padding: 0;
  height: 30px;
  float: left;
  overflow: hidden;
  margin: 0 0 0 3px;
  text-indent: -9999em;
  background: $url_8 no-repeat 0 -9999em;
}

//original selectors
//.panel .devices li.text, #footer .devices li.text
%extend_3 {
  width: 255px;
  padding: 10px 0 0;
  clear: left;
}

//original selectors
//.headingLarge, .heading.large, .heading-large
%extend_4 {
  margin: 0 0 10px;
  line-height: normal;
  font-size: 35px;
}

//original selectors
//.headingSmall, .heading.small, .heading-small, .headingSmallColour
%extend_5 {
  margin: 0 0 10px;
  line-height: normal;
  font-size: 20px;
}

//original selectors
//.headingColour, .heading.colour, .heading-colour
%extend_6 {
  margin: 0 0 10px;
  line-height: normal;
  font-size: 20px;
}

//original selectors
//#lb-login .remember, #lb-signup-createAccount .terms
%extend_7 {
  width: auto;
  padding: 5px 0 0;
  float: left;
}

//original selectors
//#lb-login .remember label, #lb-signup-createAccount .terms label
%extend_8 {
  width: auto;
  padding: 0 0 0 5px;
  margin: 0;
}

//original selectors
//#lb-login .remember input, #lb-signup-createAccount .terms input
%extend_9 {
  float: left;
  margin: 0;
  position: relative;
  top: 5px;
}


@font-face {
  font-family: $font_0;
  src: $url_0;
  src: $url_1 format('embedded-opentype'), $url_2 format('woff'), $url_3 format('truetype'), $url_4 format('svg');
  font-weight: normal;
  font-style: normal;
}
#user-locale {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999;
  cursor: pointer;
  background: $white;
  li {
    padding: 4px 10px 4px 32px;
    position: relative;
    color: $color_mountain_mist_approx;
    white-space: nowrap;
    + li {
      border-top: 1px solid $color_celeste_approx;
      display: none;
      &:before {
        content: '';
        width: 100%;
        border-top: 1px solid $white;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    [class*="flag-16"] {
      position: absolute;
      top: 6px;
      left: 10px;
      text-indent: 0;
    }
  }
  &.active li {
    display: block;
    &:hover {
      background: $color_gallery_approx;
    }
  }
}
#header {
  #user-locale {
    border: 1px solid $color_celeste_approx;
    border-top: none;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0 0 4px 4px;
    background: $color_gallery_approx;
    background: linear-gradient(to bottom, $alabaster_100 0,$color_mercury_approx 100%);
  }
  .need-help {
    position: absolute;
    top: 3px;
    right: 20px;
    p {
      border-bottom: 1px solid $color_alto_approx;
      margin: 0 0 5px;
    }
    .btn {
      float: right;
    }
  }
  .companyLogo {
    width: 350px;
    height: 100%;
    float: left;
    overflow: hidden;
    text-indent: -9999em;
    background: $url_5 no-repeat 0 0;
  }
  .menu {
    float: right;
    .dropdown {
      position: absolute;
      top: -9999em;
      left: 0;
      li {
        display: block;
      }
      a {
        display: block;
      }
    }
    .menu-createAccount {
      border-top: 1px solid $color_alto_approx;
      a {
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 0 0 5px 5px;
        &:hover {
          //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
          border-radius: 0 0 5px 5px;
        }
      }
    }
    #login-dropdown {
      width: 258px;
      display: none;
      position: absolute;
      z-index: 3;
      .slidingPanel {
        padding: 30px 0 0;
        float: left;
        position: relative;
      }
      .top {
        width: 100%;
        height: 30px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background: $url_6 no-repeat 0 0;
      }
      .wrapper {
        padding: 0 18px 18px;
        float: left;
        position: relative;
        z-index: 1;
        background: $url_6 no-repeat 0 bottom;
      }
      input[type="email"] {
        width: 206px;
      }
      input[type="password"] {
        width: 206px;
      }
      a {
        width: auto;
        height: 38px;
        padding: 0;
        float: left;
        line-height: 38px;
        font-family: $font_1, $font_2;
        font-size: 13px;
        color: $color_delta_approx;
        &:hover {
          text-decoration: underline;
        }
      }
      input[type="submit"] {
        padding: 4px 12px;
        float: right;
      }
      form {
        display: none;
        position: relative;
      }
      .btn {
        width: auto;
        float: right;
        overflow: hidden;
      }
      .facebook {
        width: 100%;
        float: left;
        text-align: center;
        p {
          border-bottom: 1px solid $color_alto_approx;
          span {
            padding: 5px 10px;
            position: relative;
            top: 10px;
            background: $white;
          }
        }
        a {
          width: 100%;
          height: auto;
          display: block;
          line-height: normal;
          text-align: center;
          font-size: 15px;
          font-weight: bold;
          color: $color_azure_approx;
        }
      }
    }
    input[type="submit"] {
      line-height: 24px;
    }
    input[type="button"] {
      line-height: 24px;
    }
    .login label {
      position: absolute;
      top: 8px;
      left: 10px;
    }
  }
  .menu-createAccount a span {
    width: 14px;
    height: 10px;
    overflow: hidden;
    position: absolute;
    top: 11px;
    right: 10px;
  }
}

#lb-signup-createAccount {
  width: 470px;
  .disclaimer {
    font-size: 16px;
    + p {
      margin-top: 20px;
    }
  }
  .terms {
    @extend %extend_7;
    label {
      @extend %extend_8;
    }
    input {
      @extend %extend_9;
    }
  }
  .controls {
    width: 330px;
    overflow: hidden;
    margin: 0 auto 10px;
  }
  form {
    margin: 0 0 20px;
  }
  input[type="text"] {
    width: 320px;
  }
  input[type="password"] {
    width: 320px;
  }
  input[type="email"] {
    width: 320px;
  }
  p {
    margin: 0;
    clear: both;
  }
  a:hover {
    text-decoration: underline;
  }
}
.lt-ie8 {
  #header .menu #login-dropdown .facebook {
    width: 222px;
  }
  .testimonial .reviewer {
    top: -3px;
  }
  #lb-signup-createAccount .inputWrapper {
    float: none;
  }
  #lb-login .inputWrapper {
    float: none;
  }
}
#heroBanner {
  .carousel {
    width: 100%;
  }
  .banner {
    width: 100%;
    float: left;
    display: none;
    position: relative;
    &:first-child {
      display: block;
    }
  }
  .loggedIn {
    padding: 10px 0 20px;
    p {
      margin-bottom: 30px;
    }
  }
}
#content {
  .pageIntro {
    img {
      position: absolute;
      right: 20px;
      bottom: -10px;
    }
    [class^="wrapper"] {
      overflow: visible;
    }
    .sash {
      overflow: hidden;
      margin: 0;
      position: absolute;
      right: 20px;
      bottom: -10px;
      //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
      transform: rotate(15deg);
      span {
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-family: $font_3, $font_4, $font_5;
        font-size: 21px;
        font-weight: 600;
      }
      .attr {
        font-size: 39px;
        line-height: 32px;
      }
    }
  }
  li {
    line-height: 20px;
  }
}
.pageIntro {
  position: relative;
  z-index: 2;
  text-align: center;
  * {
    position: relative;
    z-index: 2;
  }
  .clearfix {
    position: inherit;
    z-index: 0;
  }
  .title {
    margin: 0 0 10px;
    line-height: normal;
  }
  .subtitle {
    margin: 0;
    + .subtitle {
      margin-top: 20px;
    }
  }
}
.headingLarge {
  @extend %extend_4;
}
.heading {
  margin: 0 0 10px;
  line-height: normal;
  font-size: 25px;
  &.large {
    @extend %extend_4;
  }
  &.small {
    @extend %extend_5;
  }
  &.colour {
    @extend %extend_6;
  }
}
.heading-large {
  @extend %extend_4;
}
.headingSmall {
  @extend %extend_5;
}
.heading-small {
  @extend %extend_5;
}
.headingSmallColour {
  @extend %extend_5;
}
.headingColour {
  @extend %extend_6;
}
.heading-colour {
  @extend %extend_6;
}
[class*="icon-"] {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1;
  text-indent: -9999em;
}
[class^="icons26-colour-"] {
  width: 26px;
  height: 26px;
  display: block;
  overflow: hidden;
  background: $url_9 no-repeat 0 -9999em;
}
.icons26-colour-sync {
  background-position: 0 0;
}
.icons26-colour-file {
  background-position: 0 -26px;
}
.icons26-colour-padlock {
  background-position: 0 -52px;
}
[class^="icon48-"] {
  width: 48px;
  height: 48px;
  display: block;
  overflow: hidden;
  background: $url_10 no-repeat 0 -9999em;
}
.icon48-list {
  background-position: 0 0;
}
.icon48-clipboard {
  background-position: 0 -48px;
}
.icon48-info {
  background-position: 0 -96px;
}
[class^='icon90-'] {
  width: 90px;
  height: 90px;
  display: block;
  overflow: hidden;
  background: $url_11 no-repeat 0 -9999em;
}
.icon90-upload {
  background-position: 0 0;
}
.icon90-cog {
  background-position: 0 -90px;
}
.icon90-laptop {
  background-position: 0 -180px;
}
.icon90-folder {
  background-position: 0 -270px;
}
.icon90-users {
  background-position: 0 -360px;
}
.icon90-app {
  background-position: 0 -450px;
}
.icon90-padlock {
  background-position: 0 -540px;
}
.icon90-map {
  background-position: 0 -630px;
}
.icon90-file {
  background-position: 0 -720px;
}
.icon90-support {
  background-position: 0 -810px;
}
.icon90-fileVersion {
  background-position: 0 -900px;
}
.icon90-folderAccess {
  background-position: 0 -990px;
}
[class^="iconGrey-35"] {
  width: 35px;
  height: 35px;
  display: block;
  overflow: hidden;
  background: $url_12 no-repeat 0 -9999em;
}
.iconGrey-35-padlock {
  background-position: 0 -30px;
}
.iconGrey-35-sync {
  background-position: 0 -65px;
}
.iconGrey-35-file {
  background-position: 0 -100px;
}
.iconGrey-35-cog {
  background-position: 0 -135px;
}
.iconGrey-35-money {
  background-position: 0 -170px;
}
.iconGrey-35-exclamation {
  background-position: 0 -205px;
}
.iconGrey-35-infinity {
  background-position: 0 -240px;
}
.iconGrey-35-addUser {
  background-position: 0 -275px;
}
.iconGrey-35-camera {
  background-position: 0 -310px;
}
[class*="icon-social-large"] {
  width: 90px;
  height: 90px;
  background: $url_13 no-repeat 0 -9999em;
}
.icon-social-large-facebook {
  background-position: 0 0;
}
.icon-social-large-twitter {
  background-position: 0 -90px;
}
.testimonial {
  float: left;
  .review {
    padding: 15px;
    border: 1px solid $color_alto_approx;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
    z-index: 1;
    background: $color_desert_storm_approx;
    span {
      padding: 14px 0 0;
      display: block;
      &:first-child {
        padding: 0;
      }
    }
  }
  .reviewer {
    padding: 25px 0 0 15px;
    top: -1px;
    z-index: 2;
    font-size: 14px;
    background: $url_14 no-repeat 20px 0;
  }
}
.socialReviews {
  width: 100%;
  float: left;
}
.tweet {
  margin: 0;
  p {
    margin: 0;
  }
  .user {
    padding-left: 24px;
    margin: 0 0 8px;
    position: relative;
    .name {
      font-size: 15px;
      color: $color_rangoon_green_approx;
    }
    .tag {
      font-size: 12px;
      color: $color_suva_gray_approx;
    }
    [class*="icon"] {
      position: absolute;
      top: 1px;
      left: 0;
    }
  }
  + .tweet {
    padding: 19px 0 0;
    border-top: 1px solid $color_celeste_approx;
    margin: 20px 0 0;
  }
  .message em {
    color: $color_fun_blue_approx;
  }
}
.fb-status {
  width: 100%;
  padding: 10px 10px 20px;
  border: 1px solid $color_periwinkle_gray_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 2px;
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  float: left;
  p {
    margin: 0;
  }
  .user {
    width: 100%;
    padding: 0 5px 12px;
    border-bottom: 1px solid $mercury;
    float: left;
    margin: 0 0 15px;
    > * {
      width: 100%;
      float: left;
    }
    .name {
      font-size: 13px;
      font-weight: bold;
      color: $color_azure_approx;
    }
    .date {
      font-size: 12px;
      color: $color_mountain_mist_approx;
    }
  }
  .message {
    padding: 0 5px;
    font-size: 13px;
    color: $color_tundora_approx;
  }
  + .fb-status {
    margin-top: 15px;
  }
}
.rancho {
  margin: 0 0 40px;
  line-height: 30px;
  font-family: $font_6, $font_7, $font_8, $font_9;
  font-size: 30px;
  color: $color_tory_blue_approx;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: rotate(-5deg);
}
#lb-login {
  width: 350px;
  input[type="text"] {
    width: 290px;
  }
  input[type="password"] {
    width: 290px;
  }
  input[type="email"] {
    width: 290px;
  }
  .btn {
    float: right;
  }
  .controls {
    width: 300px;
    overflow: hidden;
    margin: 0 auto 10px;
    a:hover {
      text-decoration: underline;
    }
  }
  .remember {
    @extend %extend_7;
    label {
      @extend %extend_8;
    }
    input {
      @extend %extend_9;
    }
  }
}
[class*="js-popup-"] {
  cursor: pointer;
  text-decoration: underline;
}
.overview {
  .features {
    width: 65%;
    float: left;
    p {
      width: 45%;
      float: left;
      margin: 0;
      + p {
        margin-left: 5%;
      }
      strong {
        min-height: 32px;
        padding: 0 0 0 36px;
        display: block;
        margin: 0 0 5px;
        line-height: 32px;
        position: relative;
        [class*="iconGlobal-"] {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .testimonials {
    width: 35%;
    float: right;
  }
}
.wrapper_no_border {
  border: none!important;
}
.hidden-el {
  height: 1px !important;
  padding: 0 !important;
  width: 1px;
  left: -3000px;
  position: absolute;
}
#password-show {
  cursor: pointer;
}
#password-hidden {
  display: none;
}
#mainMenu ul {
  width: 100%;
  float: left;
  li {
    width: 100%;
    display: block;
    a {
      padding: 7px 10px;
      float: none !important;
      display: block;
      &:hover {
        background: $color_gallery_approx;
      }
    }
  }
}
.panel .devices {
  float: right;
  margin: 0;
  li {
    @extend %extend_1;
    &.icon-windows {
      background-position: 0 2px;
    }
    &.icon-mac {
      background-position: 0 -30px;
    }
    &.icon-linux {
      background-position: 0 -60px;
    }
    &.icon-ipad {
      background-position: 0 -90px;
    }
    &.icon-iphone {
      background-position: 0 -118px;
    }
    &.icon-android {
      background-position: 0 -150px;
    }
    &.icon-blackberry {
      background-position: 0 -176px;
    }
    &.icon-kindleFire {
      background-position: 0 -210px;
    }
    &.text {
      @extend %extend_3;
    }
  }
  li[class^="icon-"] {
    @extend %extend_2;
    &:first-child {
      margin: 0;
    }
  }
}
body .testimonial p {
  margin: 0;
  position: relative;
}
.centerIt .rancho {
  width: 200px;
  padding: 0 40px 0 0;
  float: left;
  margin: 0 30px 0 0;
  text-align: center;
  background: $url_15 no-repeat right bottom;
}
